import React from "react"

class MainPage extends React.Component{
    render(){
        return(
            <p>Test Component</p>
        )
    }
}

export default MainPage